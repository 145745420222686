<template>
  <div id="map" ref="map"></div>
</template>

<script>
export default {
  name: 'Map',
  props: { type: String, zoom: String },
  data() {
    return {
      images: [require('../assets/carbo_marker.png'), require('../assets/dealer_marker.png')],
      json: require('../assets/dealers.json'),
      map: null,
      prevInfoWindow: false,
      sicomMarker: null
    }
  },
  methods: {
    centerOnUser() {
      if (this.userPosition) {
        this.center = this.userPosition
      }
    },
    setUserPosition(position) {
      this.userPosition = position
    }
  },
  mounted() {
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      mapId: '4e25fb9db6647317',
      center: { lat: 50.124058, lng: 8.754576 },
      zoom: parseInt(this.zoom),
      mapTypeControl: false,
      streetViewControl: false,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM
      },
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP
      }
    })

    if (this.type === 'dealers') {
      this.json.forEach(element => {
        if (element.name == 'Carbo Brake') {
          element.icon = this.images[0]
        } else {
          element.icon = this.images[1]
        }

        let a = new window.google.maps.Marker({
          position: { lat: parseInt(element.lat), lng: parseInt(element.lon) },
          title: element.name,
          icon: element.icon,
          map: this.map
        })

        const infowindow = new window.google.maps.InfoWindow({
          content: element.info
        })
        a.addListener('mousedown', () => {
          if (this.prev_infowindow) {
            this.prev_infowindow.close()
          }

          this.prev_infowindow = infowindow
          infowindow.open(this.map, a)
        })
      })
    } else {
      const contentString =
        '<h3 class="infoTitle" class="firstHeading">Carbo Brake</h1>' +
        '<p class="infoAddress">Orber Strasse 4A - Klassikstadt<br>60386Frankfurt (HE) - Germany</p>'

      const infowindow = new window.google.maps.InfoWindow({
        content: contentString
      })

      this.sicomMarker = new window.google.maps.Marker({
        position: { lat: 50.124058, lng: 8.754576 },
        icon: this.images[0],
        map: this.map
      })

      this.sicomMarker.addListener('mousedown', () => {
        infowindow.open(this.map, this.sicomMarker)
      })
    }
  }
}
</script>

<style lang="scss">
.fourth {
  filter: brightness(0.5) blur(6px);
}
.third {
  filter: brightness(0.6) blur(4px);
}
.second {
  filter: brightness(0.8) blur(2px);
}
.active {
  filter: brightness(1) blur(0px);
}
</style>
