<template>
  <div class="contact-us">
    <!--<h1 data-aos="zoom-in" data-aos-delay="1000" data-aos-duration="1000">{{ $t('contact-us-title') }}</h1>-->
    <!-- MAPS API KEY   AIzaSyD72OIxoYbgkbSB9t_qztwjn4N2jkjDQtg -->

    <Map zoom="12" type="contact"></Map>

    <div class="contacts-container">
      <div class="data-container" data-aos="fade-right" data-aos-duration="1000">
        <img :src="images[0]" alt="CarboBrake Logo"/>

        <p class="address">
          <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
          Orber Strasse 4A - Klassikstadt - 60386<br />
          Frankfurt - Germany
        </p>
        <p class="phone">
          <font-awesome-icon :icon="['fas', 'phone-alt']" />
          <a href="tel:+4915154162790" property="telephone"> +49 1515 4162790</a>
        </p>
        <br />
        <p class="social">
          <font-awesome-icon :icon="['fab', 'facebook-square']" />
          <a title="Sicom on Facebook" href="https://www.facebook.com/CARBObrake/" target="_blank"> Facebook</a>
        </p>
        <p class="social">
          <font-awesome-icon :icon="['fab', 'linkedin']" />
          <a
            title="Sicom on Linkedin"
            href="https://www.linkedin.com/company/sicom-automotive?trk=ppro_cprof"
            target="_blank"
          >
            Linkedin</a
          >
        </p>
        <p class="social">
          <font-awesome-icon :icon="['fab', 'instagram']" />
          <a title="Sicom on Instagram" href="https://www.instagram.com/carbobrake/" target="_blank"> Instagram</a>
        </p>
      </div>
      <div class="form-container" data-aos="fade-left" data-aos-delay="1300" data-aos-duration="1000">
        <ContactForm />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import Map from '../components/Map.vue'

export default {
  name: 'ContactUs',
  components: {
    ContactForm,
    Map
  },
  data() {
    return {
      images: [
        require('../assets/logo_carbo_brake.svg'),
        require('../assets/carbo_marker.png')
      ]
    }
  },
  methods: {},
  computed: {
    areCookiesAccepted() {
      return this.$refs.cookieBanner.isAccepted()
    }
  },
  mounted() {
    console.log(this.areCookiesAccepted)
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>
<style lang="scss"></style>
