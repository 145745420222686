<template>
  <form id="contact-form" @submit.prevent>
    <label for="user_name" id="name-label">{{ $t('form-label-name') }}*</label>
    <input type="text" name="user_name" v-model="form.name" @input="onFieldInput('name')" />
    <span v-if="validation.name" class="error-message">{{ $t('form-validation-name') }}</span> <br />

    <label id="email-label">{{ $t('form-label-email') }}*</label>
    <input type="email" name="user_email" v-model="form.email" @input="onFieldInput('email')" />
    <span v-if="validation.email" class="error-message">{{ $t('form-validation-email') }}</span> <br />

    <label id="company-label">{{ $t('form-label-company') }}</label>
    <input type="text" name="user_company" /> <br />

    <label>{{ $t('form-label-phone') }}</label>
    <input type="number" name="user_phone" /> <br />

    <label id="message-label">{{ $t('form-label-message') }}*</label>
    <textarea name="message" v-model="form.message" @input="onFieldInput('message')"></textarea>
    <span v-if="validation.message" class="error-message">{{ $t('form-validation-message') }}</span> <br />

    <button type="submit" :class="{ sent: messageSent }" :disabled="messageSent || wait" @click="onClick">
      <span v-if="!this.wait && !this.messageSent">{{ $t('form-button-ready') }}</span>
      <span v-if="this.wait && !this.messageSent">{{ $t('form-button-wait') }}</span>
      <span v-if="this.messageSent">{{ $t('form-button-sent') }}</span>
    </button>
  </form>
</template>

<script>
import emailjs from 'emailjs-com'

export default {
  name: 'ContactForm',
  data() {
    return {
      form: {},
      validation: {},
      wait: false,
      messageSent: false
    }
  },
  methods: {
    isValidForm() {
      if (!this.form.name) {
        this.validation = { ...this.validation, name: 'Insert a name' }
      }

      if (!this.form.email || !this.validEmail(this.form.email)) {
        this.validation = { ...this.validation, email: 'Insert a valid email' }
      }

      if (!this.form.message) {
        this.validation = { ...this.validation, message: 'Insert a message' }
      }

      return Object.keys(this.validation).length === 0
    },
    onFieldInput(fieldName) {
      delete this.validation[fieldName]
    },
    onClick() {
      if (this.isValidForm()) {
        this.wait = true
        this.sendEmail()
      }
    },

    validEmail(email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    sendEmail() {
      emailjs.sendForm('service_scpjqrp', 'template_jrm6ufo', '#contact-form', 'user_N8h2rhpdyYH80hVKM8zmJ').then(
        result => {
          console.log('SUCCESS!', result.status, result.text)
          this.wait = false
          this.messageSent = true
        },
        error => {
          console.log('FAILED...', error)
          this.wait = false
          this.messageSent = false
        }
      )
    }
  }
}
</script>
